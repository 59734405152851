/*************** Icon font ***************/

[class^="taIcon-"], [class*=" taIcon-"] {
    font-family: 'tradair-icons';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    background: none;
    font-size: 15px;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-stroke: 1px rgba(0,0,0,0.1);
}


/*************** END Icon font ***************/

html, body { margin: 0; height: 100%; overflow-y: hidden; }
html {font-family: Roboto, Arial, Helvetica, sans-serif; font-size: 62.5%;}

/* disable selection in all element */
/* TODO: Check if it does not cause a performance issue */
*::selection { background: transparent; }
*[contenteditable=true]::selection, .taTable *::selection, .input::selection, input::selection { background: #3399FF; }

body {
    background: #F2F4F5;
    overflow-y: hidden;
}

body.mako {
    background: #141617;
}

.taClear:after {content: ".";display: block;height: 0;clear: both;visibility: hidden;}
a {text-decoration: none;}
.ui-tabs{padding:0px!important}

.flexContRow, .boxFlexContHorizontal {
    display: -webkit-flex;
    display: -moz-flex;
    display: flex;
    -webkit-flex-flow: row;
    -moz-flex-flow: row;
    flex-flow: row;
    -webkit-align-items: stretch;
    -moz-align-items: stretch;
    align-items: stretch;
    min-height: 0;
}

.flexContCol, .boxFlexContVertical {
    display: -webkit-flex;
    display: -moz-flex;
    display: flex;
    -webkit-flex-flow: column;
    -moz-flex-flow: column;
    flex-flow: column;
    -webkit-align-items: stretch;
    -moz-align-items: stretch;
    align-items: stretch;
    min-width: 0;
}

.flexContRowReverse {
    flex-direction: row-reverse;
}

.flex1, .boxFlexOne { -webkit-flex: 1; -moz-flex: 1; flex: 1; flex-basis: 0; min-width:0; min-height: 0; }
.flex2              { -webkit-flex: 2; -moz-flex: 2; flex: 2; flex-basis: 0; min-width:0; min-height: 0; }
.flex3              { -webkit-flex: 3; -moz-flex: 3; flex: 3; flex-basis: 0; min-width:0; min-height: 0; }
.flex4              { -webkit-flex: 4; -moz-flex: 4; flex: 4; flex-basis: 0; min-width:0; min-height: 0; }
.flex5              { -webkit-flex: 5; -moz-flex: 5; flex: 5; flex-basis: 0; min-width:0; min-height: 0; }
.flex6              { -webkit-flex: 6; -moz-flex: 6; flex: 6; flex-basis: 0; min-width:0; min-height: 0; }
.flex7              { -webkit-flex: 7; -moz-flex: 7; flex: 7; flex-basis: 0; min-width:0; min-height: 0; }
.flex8              { -webkit-flex: 8; -moz-flex: 8; flex: 8; flex-basis: 0; min-width:0; min-height: 0; }
.flex9              { -webkit-flex: 9; -moz-flex: 9; flex: 9; flex-basis: 0; min-width:0; min-height: 0; }

.flexBasisAuto { flex-basis: auto; }
.flexSpaceBetween { justify-content: space-between; }

.cloudcarousel { visibility: hidden; }

.taGlow {
    box-shadow: inset 0 0 2rem 1rem rgba(255, 255, 255, 0.7);
}

td.taGlow, tr.taGlow {
    /*background: rgba(153, 214, 255, 0.4) !important;*/
    /*border-bottom-color: #90d0fc !important;*/
    /*border-bottom-style: solid !important;*/
    /*border-bottom-width: 1px !important;*/
    /*border-top-color: #90d0fc !important;*/
    /*border-top-style: solid !important;*/
    /*border-top-width: 0 !important;*/
    /*box-shadow: none;*/
}

.taScrollBar::-webkit-scrollbar {
    width: 0.8rem;
    height: 0.8rem;
}

.taScrollBar::-webkit-scrollbar-corner {
    display: none;
}

.taScrollBar::-webkit-scrollbar-track {
    background: none;
}

.taScrollBar::-webkit-scrollbar-corner {
    display: none;
}

.taScrollBar::-webkit-scrollbar-thumb {
    border-radius: 0.6rem;
}

.taButtonGradient {
    text-align: center;
    line-height: 1.9rem;
    cursor: pointer;
    color:#FFF;
    border-radius: 3px;
    background: rgb(127,151,185); /* Old browsers */
    background: -moz-linear-gradient(top,  rgba(127,151,185,1) 0%, rgba(127,151,185,1) 50%, rgba(85,110,147,1) 51%, rgba(85,110,147,1) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(127,151,185,1)), color-stop(50%,rgba(127,151,185,1)), color-stop(51%,rgba(85,110,147,1)), color-stop(100%,rgba(85,110,147,1))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  rgba(127,151,185,1) 0%,rgba(127,151,185,1) 50%,rgba(85,110,147,1) 51%,rgba(85,110,147,1) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  rgba(127,151,185,1) 0%,rgba(127,151,185,1) 50%,rgba(85,110,147,1) 51%,rgba(85,110,147,1) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  rgba(127,151,185,1) 0%,rgba(127,151,185,1) 50%,rgba(85,110,147,1) 51%,rgba(85,110,147,1) 100%); /* IE10+ */
    background: linear-gradient(to bottom,  rgba(127,151,185,1) 0%,rgba(127,151,185,1) 50%,rgba(85,110,147,1) 51%,rgba(85,110,147,1) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7f97b9', endColorstr='#556e93',GradientType=0 ); /* IE6-9 */
}

.taButtonGradientReversed {
    background: rgb(85,110,147);
    background: -moz-linear-gradient(top,  rgba(85,110,147,1) 0%, rgba(85,110,147,1) 49%, rgba(127,151,185,1) 50%, rgba(127,151,185,1) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(85,110,147,1)), color-stop(49%,rgba(85,110,147,1)), color-stop(50%,rgba(127,151,185,1)), color-stop(100%,rgba(127,151,185,1)));
    background: -webkit-linear-gradient(top,  rgba(85,110,147,1) 0%,rgba(85,110,147,1) 49%,rgba(127,151,185,1) 50%,rgba(127,151,185,1) 100%);
    background: -o-linear-gradient(top,  rgba(85,110,147,1) 0%,rgba(85,110,147,1) 49%,rgba(127,151,185,1) 50%,rgba(127,151,185,1) 100%);
    background: -ms-linear-gradient(top,  rgba(85,110,147,1) 0%,rgba(85,110,147,1) 49%,rgba(127,151,185,1) 50%,rgba(127,151,185,1) 100%);
    background: linear-gradient(to bottom,  rgba(85,110,147,1) 0%,rgba(85,110,147,1) 49%,rgba(127,151,185,1) 50%,rgba(127,151,185,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#556e93', endColorstr='#7f97b9',GradientType=0 );
}

.taGreenBadge {
    border-radius: 0.2rem;
    /*background: rgba(17, 123, 52, 0.6);*/
    /*background: #145d40;*/
}

.taRedBadge {
    border-radius: 0.2rem;
    /*background: rgba(183, 41, 39, 0.6);*/
    /*background: #782c38;*/
}

.taGreenBadge.taGlow, .taRedBadge.taGlow {
    box-shadow: inset 0 0 2rem 0.1rem rgba(255, 255, 255, 0.7);
}

.taIcon {
    /*background: url("/images/iconSprite.png") no-repeat top left;*/
    display: block;
    float: right;
}

.taIcon-down-arrow:before {
    content: "\e60c";
}
.taIcon-filter:before {
    content: "\e60d";
}
.taIcon-sort:before {
    content: "\e60e";
}
.taIcon-sort-asc:before {
    content: "\e610";
}
.taIcon-sort-desc:before {
    content: "\e611";
}
.taIcon-up-arrow:before {
    content: "\e60f";
}
.taIcon-rfs:before {
    content: "\e60b";
}
.taIcon-close:before {
    content: "\e600";
}
.taIcon-full-screen:before {
    content: "\e609";
}
.taIcon-pop:before {
    content: "\e609";
}
.taIcon-addUser:before {
    content: "\e601";
}
.taIcon-flip:before {
    content: "\e602";
}
.taIcon-settings:before {
    content: "\e60a";
}
.taIcon-table-settings:before {
    content: "\e603";
}
.taIcon-calendar:before {
    content: "\e604";
}
.taIcon-double:before {
    content: "\e605";
}
.taIcon-help:before {
    content: "\e606";
}
.taIcon-minimize:before {
    content: "\e607";
}
.taIcon-plus:before {
    content: "\e608";
}
.taIcon-quality1:before {
    content: "\e612";
}
.taIcon-quality2:before {
    content: "\e613";
}
.taIcon-quality3:before {
    content: "\e614";
}
.taIcon-quality4:before {
    content: "\e615";
}
.taIcon-pause:before {
    content: "\e616";
}
.taIcon-play:before {
    content: "\e617";
}
.taIcon-stop:before {
    content: "\e618";
}
.taIcon-broadcast:before {
    content: "\e619";
}
.taIcon-chat:before {
    content: "\e61a";
}
.taIcon-reports:before {
    content: "\e61b";
}
.taIcon-ticket:before {
    content: "\e61c";
}
.taIcon-save-as:before {
    content: "\e620";
}
.taIcon-reset:before {
    content: "\e61e";
}
.taIcon-reset-all:before {
    content: "\e61f";
}
.taIcon-right-caret:before {
    content: "\e623";
}
.taIcon-left-caret:before {
    content: "\e622";
}
/*.taIcon-save:before {*/
    /*content: "\e61d";*/
/*}*/
.taIcon-delete:before {
    content: "\e624";
}
.taIcon-pencil:before {
    content: "\e905";
}
.taIcon-bars-big:before {
    content: "\e625";
}
.taIcon-save:before {
    content: "\e621";
}
.taIcon-list:before {
    content: "\e626";
}

.cacheLoaderPopup_popup:before {
    content: "";
    background: #3C577B;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
}
.cacheLoaderPopup_popup { background: #152a47; opacity: 1; color: white; }
.cacheLoaderPopup_popup > .ui-dialog-titlebar { display: none; }
.cacheLoaderPopup_popup { border: none !important; overflow: visible !important; }
.cacheLoaderPopup_popup input[type=button] { background: none !important; }
.cacheLoaderPopup_caption { margin-top: 3rem; }

.cacheLoaderPopup_logo_default {
    width: 43.4rem;
    height: 7rem;
    background: url(/images/mobile/tradair.png) no-repeat;
}

.cacheLoaderPopup_logo_elixium {
    height: 116px;
    background: url(/images/elixium250x116.png) no-repeat center;
}

.cacheLoaderPopup_logo_parfx {
    width: 44rem;
    height: 11rem;
    background: url(/images/parfx_large.png) no-repeat;
}

.cacheLoaderPopup_progressBar {
    width: 43.4rem;
    margin-top: 1.3rem;
    position: relative;
}

.cacheLoaderPopup_progressBarBack {
    height: 0.2rem;
    background: white;
    position: absolute;
    top: 0.1rem;
    left: 0;
    width: 100%;
}

.cacheLoaderPopup_progressBarFront {
    height: 0.4rem;
    background: #36a2fd;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    -webkit-transform: width .1s;
}

.mako .taWidget.makoWidget {
    background: #2e3232;
    border-bottom: none;
    border-radius: 0;
}


.jqgrow td{cursor:pointer;}

.taFlag {
    width: 1.8rem;
    height: 1.2rem;
}

.taFlag.taFlag-ad {background-position:-1147px -166px }
.taFlag.taFlag-ae {background-position:-1165px -166px }
.taFlag.taFlag-af {background-position:-1183px -166px }
.taFlag.taFlag-ag {background-position:-1201px -166px }
.taFlag.taFlag-ai {background-position:-1219px -166px }
.taFlag.taFlag-al {background-position:-1237px -166px }
.taFlag.taFlag-am {background-position:-1255px -166px }
.taFlag.taFlag-an {background-position:-1273px -166px }
.taFlag.taFlag-ao {background-position:-1291px -166px }
.taFlag.taFlag-ar {background-position:-1309px -166px }
.taFlag.taFlag-as {background-position:-1327px -166px }
.taFlag.taFlag-at {background-position:-1345px -166px }
.taFlag.taFlag-au {background-position:-1363px -166px }
.taFlag.taFlag-aw {background-position:-1381px -166px }
.taFlag.taFlag-az {background-position:-1399px -166px }
.taFlag.taFlag-ba {background-position:-1129px -178px }
.taFlag.taFlag-bb {background-position:-1147px -178px }
.taFlag.taFlag-bd {background-position:-1165px -178px }
.taFlag.taFlag-be {background-position:-1183px -178px }
.taFlag.taFlag-bf {background-position:-1201px -178px }
.taFlag.taFlag-bg {background-position:-1219px -178px }
.taFlag.taFlag-bh {background-position:-1237px -178px }
.taFlag.taFlag-bi {background-position:-1255px -178px }
.taFlag.taFlag-bj {background-position:-1273px -178px }
.taFlag.taFlag-bm {background-position:-1291px -178px }
.taFlag.taFlag-bn {background-position:-1309px -178px }
.taFlag.taFlag-bo {background-position:-1327px -178px }
.taFlag.taFlag-br {background-position:-1345px -178px }
.taFlag.taFlag-bs {background-position:-1363px -178px }
.taFlag.taFlag-bt {background-position:-1381px -178px }
.taFlag.taFlag-bv {background-position:-1399px -178px }
.taFlag.taFlag-bw {background-position:-1129px -190px }
.taFlag.taFlag-by {background-position:-1147px -190px }
.taFlag.taFlag-bz {background-position:-1165px -190px }
.taFlag.taFlag-ca {background-position:-1183px -190px }
.taFlag.taFlag-cd {background-position:-1201px -190px }
.taFlag.taFlag-cf {background-position:-1219px -190px }
.taFlag.taFlag-cg {background-position:-1237px -190px }
.taFlag.taFlag-ch {background-position:-1255px -190px }
.taFlag.taFlag-ci {background-position:-1273px -190px }
.taFlag.taFlag-ck {background-position:-1291px -190px }
.taFlag.taFlag-cl {background-position:-1309px -190px }
.taFlag.taFlag-cm {background-position:-1327px -190px }
.taFlag.taFlag-cn {background-position:-1345px -190px }
.taFlag.taFlag-co {background-position:-1363px -190px }
.taFlag.taFlag-cr {background-position:-1381px -190px }
.taFlag.taFlag-cu {background-position:-1399px -190px }
.taFlag.taFlag-cv {background-position:-1129px -202px }
.taFlag.taFlag-cy {background-position:-1147px -202px }
.taFlag.taFlag-cz {background-position:-1165px -202px }
.taFlag.taFlag-de {background-position:-1183px -202px }
.taFlag.taFlag-dj {background-position:-1201px -202px }
.taFlag.taFlag-dk {background-position:-1219px -202px }
.taFlag.taFlag-dm {background-position:-1237px -202px }
.taFlag.taFlag-do {background-position:-1255px -202px }
.taFlag.taFlag-dz {background-position:-1273px -202px }
.taFlag.taFlag-ec {background-position:-1291px -202px }
.taFlag.taFlag-ee {background-position:-1309px -202px }
.taFlag.taFlag-eg {background-position:-1327px -202px }
.taFlag.taFlag-eh {background-position:-1345px -202px }
.taFlag.taFlag-er {background-position:-1363px -202px }
.taFlag.taFlag-es {background-position:-1381px -202px }
.taFlag.taFlag-et {background-position:-1399px -202px }
.taFlag.taFlag-eu {background-position:-1129px -214px }
.taFlag.taFlag-fi {background-position:-1147px -214px }
.taFlag.taFlag-fj {background-position:-1165px -214px }
.taFlag.taFlag-fk {background-position:-1183px -214px }
.taFlag.taFlag-fm {background-position:-1201px -214px }
.taFlag.taFlag-fo {background-position:-1219px -214px }
.taFlag.taFlag-fr {background-position:-1237px -214px }
.taFlag.taFlag-ga {background-position:-1255px -214px }
.taFlag.taFlag-gb {background-position:-1273px -214px }
.taFlag.taFlag-gd {background-position:-1291px -214px }
.taFlag.taFlag-ge {background-position:-1309px -214px }
.taFlag.taFlag-gf {background-position:-1327px -214px }
.taFlag.taFlag-gg {background-position:-1345px -214px }
.taFlag.taFlag-gh {background-position:-1363px -214px }
.taFlag.taFlag-gi {background-position:-1381px -214px }
.taFlag.taFlag-gl {background-position:-1399px -214px }
.taFlag.taFlag-gm {background-position:-1129px -226px }
.taFlag.taFlag-gn {background-position:-1147px -226px }
.taFlag.taFlag-gp {background-position:-1165px -226px }
.taFlag.taFlag-gq {background-position:-1183px -226px }
.taFlag.taFlag-gr {background-position:-1201px -226px }
.taFlag.taFlag-gs {background-position:-1219px -226px }
.taFlag.taFlag-gt {background-position:-1237px -226px }
.taFlag.taFlag-gu {background-position:-1255px -226px }
.taFlag.taFlag-gw {background-position:-1273px -226px }
.taFlag.taFlag-gy {background-position:-1291px -226px }
.taFlag.taFlag-hk {background-position:-1309px -226px }
.taFlag.taFlag-hm {background-position:-1327px -226px }
.taFlag.taFlag-hn {background-position:-1345px -226px }
.taFlag.taFlag-hr {background-position:-1363px -226px }
.taFlag.taFlag-ht {background-position:-1381px -226px }
.taFlag.taFlag-hu {background-position:-1399px -226px }
.taFlag.taFlag-id {background-position:-1129px -238px }
.taFlag.taFlag-ie {background-position:-1147px -238px }
.taFlag.taFlag-il {background-position:-1165px -238px }
.taFlag.taFlag-in {background-position:-1183px -238px }
.taFlag.taFlag-io {background-position:-1201px -238px }
.taFlag.taFlag-iq {background-position:-1219px -238px }
.taFlag.taFlag-ir {background-position:-1237px -238px }
.taFlag.taFlag-is {background-position:-1255px -238px }
.taFlag.taFlag-it {background-position:-1273px -238px }
.taFlag.taFlag-je {background-position:-1291px -238px }
.taFlag.taFlag-jm {background-position:-1309px -238px }
.taFlag.taFlag-jo {background-position:-1327px -238px }
.taFlag.taFlag-jp {background-position:-1345px -238px }
.taFlag.taFlag-ke {background-position:-1363px -238px }
.taFlag.taFlag-kg {background-position:-1381px -238px }
.taFlag.taFlag-kh {background-position:-1399px -238px }
.taFlag.taFlag-ki {background-position:-1129px -250px }
.taFlag.taFlag-km {background-position:-1147px -250px }
.taFlag.taFlag-kn {background-position:-1165px -250px }
.taFlag.taFlag-kp {background-position:-1183px -250px }
.taFlag.taFlag-kr {background-position:-1201px -250px }
.taFlag.taFlag-kw {background-position:-1219px -250px }
.taFlag.taFlag-ky {background-position:-1237px -250px }
.taFlag.taFlag-kz {background-position:-1255px -250px }
.taFlag.taFlag-la {background-position:-1273px -250px }
.taFlag.taFlag-lb {background-position:-1291px -250px }
.taFlag.taFlag-lc {background-position:-1309px -250px }
.taFlag.taFlag-li {background-position:-1327px -250px }
.taFlag.taFlag-lk {background-position:-1345px -250px }
.taFlag.taFlag-lr {background-position:-1363px -250px }
.taFlag.taFlag-ls {background-position:-1381px -250px }
.taFlag.taFlag-lt {background-position:-1399px -250px }
.taFlag.taFlag-lu {background-position:-1129px -262px }
.taFlag.taFlag-lv {background-position:-1147px -262px }
.taFlag.taFlag-ly {background-position:-1165px -262px }
.taFlag.taFlag-ma {background-position:-1183px -262px }
.taFlag.taFlag-mc {background-position:-1201px -262px }
.taFlag.taFlag-md {background-position:-1219px -262px }
.taFlag.taFlag-me {background-position:-1237px -262px }
.taFlag.taFlag-mg {background-position:-1255px -262px }
.taFlag.taFlag-mh {background-position:-1273px -262px }
.taFlag.taFlag-mk {background-position:-1291px -262px }
.taFlag.taFlag-ml {background-position:-1309px -262px }
.taFlag.taFlag-mm {background-position:-1327px -262px }
.taFlag.taFlag-mn {background-position:-1345px -262px }
.taFlag.taFlag-mo {background-position:-1363px -262px }
.taFlag.taFlag-mp {background-position:-1381px -262px }
.taFlag.taFlag-mq {background-position:-1399px -262px }
.taFlag.taFlag-mr {background-position:-1129px -274px }
.taFlag.taFlag-ms {background-position:-1147px -274px }
.taFlag.taFlag-mt {background-position:-1165px -274px }
.taFlag.taFlag-mu {background-position:-1183px -274px }
.taFlag.taFlag-mv {background-position:-1201px -274px }
.taFlag.taFlag-mw {background-position:-1219px -274px }
.taFlag.taFlag-mx {background-position:-1237px -274px }
.taFlag.taFlag-my {background-position:-1255px -274px }
.taFlag.taFlag-mz {background-position:-1273px -274px }
.taFlag.taFlag-na {background-position:-1291px -274px }
.taFlag.taFlag-nc {background-position:-1309px -274px }
.taFlag.taFlag-ne {background-position:-1327px -274px }
.taFlag.taFlag-nf {background-position:-1345px -274px }
.taFlag.taFlag-ng {background-position:-1363px -274px }
.taFlag.taFlag-ni {background-position:-1381px -274px }
.taFlag.taFlag-nl {background-position:-1399px -274px }
.taFlag.taFlag-no {background-position:-1129px -286px }
.taFlag.taFlag-np {background-position:-1147px -286px }
.taFlag.taFlag-nr {background-position:-1165px -286px }
.taFlag.taFlag-nu {background-position:-1183px -286px }
.taFlag.taFlag-nz {background-position:-1201px -286px }
.taFlag.taFlag-om {background-position:-1219px -286px }
.taFlag.taFlag-pa {background-position:-1237px -286px }
.taFlag.taFlag-pe {background-position:-1255px -286px }
.taFlag.taFlag-pf {background-position:-1273px -286px }
.taFlag.taFlag-pg {background-position:-1291px -286px }
.taFlag.taFlag-ph {background-position:-1309px -286px }
.taFlag.taFlag-pk {background-position:-1327px -286px }
.taFlag.taFlag-pl {background-position:-1345px -286px }
.taFlag.taFlag-pm {background-position:-1363px -286px }
.taFlag.taFlag-pn {background-position:-1381px -286px }
.taFlag.taFlag-pr {background-position:-1399px -286px }
.taFlag.taFlag-ps {background-position:-1129px -298px }
.taFlag.taFlag-pt {background-position:-1147px -298px }
.taFlag.taFlag-pw {background-position:-1165px -298px }
.taFlag.taFlag-py {background-position:-1183px -298px }
.taFlag.taFlag-qa {background-position:-1201px -298px }
.taFlag.taFlag-re {background-position:-1219px -298px }
.taFlag.taFlag-ro {background-position:-1237px -298px }
.taFlag.taFlag-rs {background-position:-1255px -298px }
.taFlag.taFlag-ru {background-position:-1273px -298px }
.taFlag.taFlag-rw {background-position:-1291px -298px }
.taFlag.taFlag-sa {background-position:-1309px -298px }
.taFlag.taFlag-sb {background-position:-1327px -298px }
.taFlag.taFlag-sc {background-position:-1345px -298px }
.taFlag.taFlag-sd {background-position:-1363px -298px }
.taFlag.taFlag-se {background-position:-1381px -298px }
.taFlag.taFlag-sg {background-position:-1399px -298px }
.taFlag.taFlag-sh {background-position:-1129px -310px }
.taFlag.taFlag-si {background-position:-1147px -310px }
.taFlag.taFlag-sk {background-position:-1165px -310px }
.taFlag.taFlag-sl {background-position:-1183px -310px }
.taFlag.taFlag-sm {background-position:-1201px -310px }
.taFlag.taFlag-sn {background-position:-1219px -310px }
.taFlag.taFlag-so {background-position:-1237px -310px }
.taFlag.taFlag-sr {background-position:-1255px -310px }
.taFlag.taFlag-st {background-position:-1273px -310px }
.taFlag.taFlag-sv {background-position:-1291px -310px }
.taFlag.taFlag-sy {background-position:-1309px -310px }
.taFlag.taFlag-sz {background-position:-1327px -310px }
.taFlag.taFlag-tc {background-position:-1345px -310px }
.taFlag.taFlag-td {background-position:-1363px -310px }
.taFlag.taFlag-tf {background-position:-1381px -310px }
.taFlag.taFlag-tg {background-position:-1399px -310px }
.taFlag.taFlag-th {background-position:-1129px -322px }
.taFlag.taFlag-tj {background-position:-1147px -322px }
.taFlag.taFlag-tk {background-position:-1165px -322px }
.taFlag.taFlag-tl {background-position:-1183px -322px }
.taFlag.taFlag-tm {background-position:-1201px -322px }
.taFlag.taFlag-tn {background-position:-1219px -322px }
.taFlag.taFlag-to {background-position:-1237px -322px }
.taFlag.taFlag-tr {background-position:-1255px -322px }
.taFlag.taFlag-tt {background-position:-1273px -322px }
.taFlag.taFlag-tv {background-position:-1291px -322px }
.taFlag.taFlag-tw {background-position:-1309px -322px }
.taFlag.taFlag-tz {background-position:-1327px -322px }
.taFlag.taFlag-ua {background-position:-1345px -322px }
.taFlag.taFlag-ug {background-position:-1363px -322px }
.taFlag.taFlag-um {background-position:-1381px -322px }
.taFlag.taFlag-us {background-position:-1399px -322px }
.taFlag.taFlag-uy {background-position:-1129px -334px }
.taFlag.taFlag-uz {background-position:-1147px -334px }
.taFlag.taFlag-va {background-position:-1165px -334px }
.taFlag.taFlag-vc {background-position:-1183px -334px }
.taFlag.taFlag-ve {background-position:-1201px -334px }
.taFlag.taFlag-vg {background-position:-1219px -334px }
.taFlag.taFlag-vi {background-position:-1237px -334px }
.taFlag.taFlag-vn {background-position:-1255px -334px }
.taFlag.taFlag-vu {background-position:-1273px -334px }
.taFlag.taFlag-wf {background-position:-1291px -334px }
.taFlag.taFlag-ws {background-position:-1309px -334px }
.taFlag.taFlag-ye {background-position:-1327px -334px }
.taFlag.taFlag-yt {background-position:-1345px -334px }
.taFlag.taFlag-za {background-position:-1363px -334px }
.taFlag.taFlag-zm {background-position:-1381px -334px }
.taFlag.taFlag-zw {background-position:-1399px -334px }
.taRadio { visibility: hidden; }
.taRadio + label {
    display: inline-block;
    width: 13px; height: 13px;
    position: relative;
    margin-left: -18px;
}
.taRadio + label:before {
    content: "";
    border: 0.1rem #fefefe solid;
    border-radius: 1.2rem;
    width: 1rem;
    height: 1rem;
    position: absolute;
    top: 0; left: 0;
}
.taRadio:checked + label:after, .taRadio:not(:disabled):active + label:after {
    content: "";
    background: rgb(43,148,187);
    background: radial-gradient(ellipse at center,  rgba(43,148,187,1) 0%,rgba(125,185,232,1) 100%);
    border-radius: 0.8rem;
    width: 0.8rem;
    height: 0.8rem;
    position: absolute;
    top: 0.2rem; left: 0.2rem;
}

.taRadio:not(:disabled):active + label:after {
    width: 0.4rem;
    height: 0.4rem;
    top: 0.4rem; left: 0.4rem;
}


.taBigRadio {
    visibility: hidden;
    width: 0;
    height: 0;
    margin: 0;
}

.taBigRadio + label {
    line-height: 2.6rem;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
    margin-right: 1rem;
}
.taBigRadio:checked + label {
    /*color: white;*/
}

.taWidgetDialog {
    /*box-shadow: 0px 5px 10px 5px rgba(5, 26, 54, 0.57);*/
    /*box-shadow: 0 0 15px 6px #000;*/
    box-shadow: 0 7px 20px 8px rgba(0,0,0,0.5);
}

/************ sales tab ************/

.taSalesTab .theme-main-color-1 {
    background-color: #484848 !important;
}
.taSalesTab .theme-main-color-2 {
    background-color: #848484 !important;
}
.taSalesTab .theme-secondary-color-1 {
    background-color: #2b2b2b !important;
}
.taSalesTab .theme-secondary-color-2 {
    background-color: #6A6A6A/*#989898*/ !important;
}
.taSalesTab .theme-text-color-1 {
    color: white !important;
}
.taSalesTab .theme-text-color-2 {
    color: white !important;
}
.taSalesTab .theme-text-color-3 {
    color: #b5b5b5 !important;
}
.taSalesTab .theme-tabs {
    background-color: #484848;/*<%- mainColor1 %>;*/
    color: white !important;
}
.taSalesTab .theme-subheader-tabs {
    background-color: #6A6A6A;/*<%- secondaryColor2 %>;*/
    color: white !important;
}
.taSalesTab .theme-tabs .ui-tabs-active {
    background-color: #616161 !important;/*<%- tinycolor(mainColor1).brighten(10) %>;*/
}
.taSalesTab .theme-subheader-tabs .ui-tabs-active {
    background-color: #505050 !important;/*<%- tinycolor(secondaryColor2).darken(10) %>;*/
}
.taSalesTab .theme-table-header {
    background-color:  #6A6A6A !important;/*<%- secondaryColor2 %> ;*/
}
.taSalesTab .theme-table-row {
    background-color: #444444 !important;/*<%- tinycolor(secondaryColor1).lighten(2) %>;*/
}
.taSalesTab .theme-table-row.theme-bordered {
    border-top: 1px solid #989898 !important;
}
.taSalesTab .theme-table-row.theme-bordered:last-of-type {
    border-bottom: 1px solid #989898 !important;
}
.taSalesTab .theme-table-row td, .taSalesTab .theme-table-header {
    border-right-color: #444444 !important;/*<%- tinycolor(secondaryColor1).lighten(10) %> !important;*/
}
.taSalesTab .theme-table-row:nth-child(even) {
    background-color:  #3a3a3a !important;/*<%- tinycolor(secondaryColor1).lighten(6) %> ;*/
}
.taSalesTab .theme-widget-base {
    background-color: #2b2b2b !important;/*<%- secondaryColor1 %>;*/
    border-bottom-color: #5c5c5c !important;/*<%- tinycolor(mainColor1).lighten(8) %> !important;*/
    color: white ;
}

.taSalesTab .theme-highlight {
    background-color: #989898 !important;/*<%- tinycolor(mainColor2).lighten(8) %> !important;*/
}

.taSalesTab .theme-highlight.theme-table-row td {
    border-right-color: #3e3e3e !important;/*<%- tinycolor(mainColor1).darken(4) %> !important;*/
    border-top-color: #d0d0d0 !important;/*<%- tinycolor(mainColor2).lighten(30) %> !important;*/
    border-bottom-color: #d0d0d0 !important;/*<%- tinycolor(mainColor2).lighten(30) %> !important;*/
}

.taSalesTab .theme-highlight.theme-table-row td:first-of-type {
    border-left-color: #d0d0d0 !important;/*<%- tinycolor(mainColor2).lighten(30) %> !important;*/
}

.taSalesTab .theme-highlight.theme-table-row td:last-of-type {
    border-right-color: #d0d0d0 !important;/*<%- tinycolor(mainColor2).lighten(30) %> !important;*/
}
.taSalesTab .theme-regular-button {
    background-color: #707070 !important;/*<%- tinycolor(mainColor2).darken(8) %>;*/
}
.taSalesTab .theme-button-selected {
    background-color: #989898 !important;/*<%- tinycolor(mainColor2).lighten(8) %>;*/
}
.taSalesTab .theme-highlight .theme-tip.theme-right:after {
    border-left: 11px solid #989898 !important;/*<%- tinycolor(mainColor2).lighten(8) %>;*/
}
.taSalesTab .theme-highlight .theme-tip.theme-left:after {
    border-right: 11px solid #989898 !important;/*<%- tinycolor(mainColor2).lighten(8) %>;*/
}
.taSalesTab .theme-highlight .theme-tip.theme-right:before {
    border-left: 13px solid #d0d0d0 !important;/*<%- tinycolor(mainColor2).lighten(30) %>;*/
}
.taSalesTab .theme-highlight .theme-tip.theme-left:before {
    border-right: 11px solid #989898 !important;/*<%- tinycolor(mainColor2).lighten(8) %>;*/
}
.taSalesTab .theme-widget-placeholder,
.taSalesTab .theme-widget-placeholder-innerCont > div{
    background-color: #616161 !important;/*<%- tinycolor(mainColor1).desaturate(25).lighten(10) %>;*/
}

.taSalesTab .theme-widget-placeholder-innerCont {
    background-color: #353535 !important;/*<%- tinycolor(secondaryColor1).lighten(4) %>;*/
}

.taSalesTab .theme-widget-placeholder-innerCont > div {
    border-bottom: 1px solid #949494 !important;/*<%- tinycolor(mainColor1).desaturate(25).lighten(30) %>;*/
    border-top: 1px solid #3b3b3b !important;/*<%- tinycolor(mainColor1).desaturate(25).darken(5) %>;*/
}
.taSalesTab .taScrollBar::-webkit-scrollbar-thumb {
    background-color: #797979;/*<%- tinycolor(secondaryColor2).desaturate(15) %>;*/
}

#head_mask {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #F2F4F5;
    color: black;
    z-index: 99999998;
}

#head_mask span {
    position: absolute;
    display: inline-block;
    top:50%;
    left: 50%;
    margin-top: -1.5rem;
    margin-left: -6rem;
    font-size: 2.2rem;
}

.credit_color_red { color: #FF0000; }
.credit_color_orange { color: #FF6600; }
.credit_color_yellow { color: #EC9D10; }
.credit_color_green { color: #00FF00; }

/* IOS Style Switch */
.onOffSwitch-input {
    position: absolute;
    margin-left: -9999px;
    visibility: hidden;
}

.onOffSwitch-input + label {
    display: block;
    position: relative;
    cursor: pointer;
    outline: none;
    user-select: none;
    padding: 2px;
    width: 74px;
    height: 20px;
    border-radius: 12px;
    transition: background 0.4s;
    overflow:hidden;
}

.onOffSwitch-input + label:before,
.onOffSwitch-input + label:after {
    display: block;
    position: absolute;
    content: "";
}

.onOffSwitch-input + label:before {
    top: 2px;
    left: 2px;
    bottom: 2px;
    right: 2px;
    border-radius: 12px;
    transition: background 0.4s;
}

.onOffSwitch-input + label:after {
    top: 4px;
    left: 4px;
    bottom: 4px;
    width: 16px;
    border-radius: 50%;
    transition: margin 0.4s, background 0.4s;
}

.onOffSwitch-input:checked + label:after {
    margin-left: 54px;
}

.onOffSwitch-input + label .labelCont {
    width: 200%;
    margin-left: -100%;
    height: 20px;
    line-height: 20px;
    overflow: hidden;
    position: relative;
    display: inline-block;
    transition: all .4s;
}

.onOffSwitch-input:checked + label .labelCont {
    margin-left: 0;
}

.onOffSwitch-input + label .labelCont span {
    position: relative;
    width: 50%;
    text-align: center;
}

.onOffSwitch-input + label .labelCont .stop {
    float:left;
    text-indent: -10px;
}

.onOffSwitch-input + label .labelCont .start {
    float:right;
    text-indent: 10px;
}


/* css for timepicker */
.ui-timepicker-div .ui-widget-header { margin-bottom: 8px; }
.ui-timepicker-div dl { text-align: left; }
.ui-timepicker-div dl dt { float: left; clear:left; padding: 0 0 0 5px; }
.ui-timepicker-div dl dd { margin: 0 10px 10px 45%; }
.ui-timepicker-div td { font-size: 90%; }
.ui-tpicker-grid-label { background: none; border: none; margin: 0; padding: 0; }

.ui-timepicker-rtl{ direction: rtl; }
.ui-timepicker-rtl dl { text-align: right; padding: 0 5px 0 0; }
.ui-timepicker-rtl dl dt{ float: right; clear: right; }
.ui-timepicker-rtl dl dd { margin: 0 45% 10px 10px; }

.ui-slider-horizontal .ui-slider-handle { background: #aaa; }
.ui-slider { background: #ccc; }

.taTrafficLight {
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 1.4rem;
}

.taCheckbox {
    display: inline-block;
    vertical-align: middle;
}
.taCheckbox label {
    width: 23px;
    height: 23px;
    cursor: pointer;
    border-radius: 4px;
    display: inline-block;
}
.taCheckbox label:after {
    content: '';
    width: 11px;
    height: 5px;
    border-width: 3px;
    border-style: solid;
    border-top: none;
    border-right: none;
    background: transparent;
    opacity: 0;
    transform: rotate(-45deg);
    margin-top: 5px;
    margin-left: 5px;
    display: inline-block;
}
.taCheckbox label:hover::after {
    opacity: 0.3;
}
.taCheckbox input[type=checkbox] {
    visibility: hidden;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
}
.taCheckbox input[type=checkbox]:checked + label:after {
    opacity: 1;
}

.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-break: auto;
    line-height: 1.42857143;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    font-size: 12px;
    opacity: 0;
    filter: alpha(opacity=0);
}
.tooltip.in {
    opacity: 0.9;
    filter: alpha(opacity=90);
}
.tooltip.top {
    margin-top: -3px;
    padding: 5px 0;
}
.tooltip.right {
    margin-left: 3px;
    padding: 0 5px;
}
.tooltip.bottom {
    margin-top: 3px;
    padding: 5px 0;
}
.tooltip.left {
    margin-left: -3px;
    padding: 0 5px;
}
.tooltip-inner {
    max-width: 200px;
    padding: 3px 8px;
    color: #ffffff;
    text-align: center;
    border-radius: 4px;
}
.tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}
.tooltip.top .tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 5px 5px 0;
}
.tooltip.top-left .tooltip-arrow {
    bottom: 0;
    right: 5px;
    margin-bottom: -5px;
    border-width: 5px 5px 0;
}
.tooltip.top-right .tooltip-arrow {
    bottom: 0;
    left: 5px;
    margin-bottom: -5px;
    border-width: 5px 5px 0;
}
.tooltip.right .tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
}
.tooltip.left .tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -5px;
    border-width: 5px 0 5px 5px;
}
.tooltip.bottom .tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 0 5px 5px;
}
.tooltip.bottom-left .tooltip-arrow {
    top: 0;
    right: 5px;
    margin-top: -5px;
    border-width: 0 5px 5px;
}
.tooltip.bottom-right .tooltip-arrow {
    top: 0;
    left: 5px;
    margin-top: -5px;
    border-width: 0 5px 5px;
}

.ta-tabs-padded, .tabControl_tabs > div.ta-tabs-padded,
.tabControl_tabs.theme-tabs-subtitle > div.ta-tabs-padded {
    border-bottom: 2px transparent solid;
    border-top: 2px transparent solid;
    box-sizing: border-box;
    text-transform: uppercase;
    padding: 5px 5px;
    margin: 10px 5px;
    font-size: 16px;
    font-weight: bold;
}

.ui-datepicker.ta-z-index {
    z-index: 200 !important;
}